




















































































































































































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import CleanTime from '@/models/CleanTime';
import User from '@/models/User';
import Customer from '@/models/Customer';
import UserRole from '@/models/user-attributes/UserRole';
import {namespace} from 'vuex-class';
import Company from '@/models/Company';
import Area from '@/models/Area';
import {Validation, validationMixin} from 'vuelidate';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/helper/ErrorMessageHandler.mixin';
import Location from '@/models/Location';
import {cleanTimeStoreActions} from '@/stores/cleanTime.store';
import Address from '@/models/Address';
import ContactPerson from '@/models/ContactPerson';
import {Permission} from '@/misc/enums/permission.enum';

const UserStore = namespace('user');
const CustomerStore = namespace('customer');
const CompanyStore = namespace('company');
const cleanTimeStore = namespace('cleanTime');

@Component({
  computed: {
    Permission() {
      return Permission;
    },
  },
  components: {
    RJAutocomplete: () => import(
      '@/components/shared/custom-vuetify/RJAutocomplete.vue'),
    CleanTimeComponent: () => import(
      /* webChunkName: "CleanTimeComponent" */
      '@/components/shared/CleanTime.component.vue'),
    UserInitialsComponent: () => import(
      /* webpackChunkName: "UserInitialsComponent" */
      '@/components/user/UserInitials.component.vue'),
    UserManageContentComponent: () => import(
      /* webpackChunkName: "UserManageComponent" */
      '@/components/user/UserManageContent.component.vue'),
    CustomerManageContentComponent: () => import(
      /* webpackChunkName: "CustomerManageComponent" */
      '@/components/customer/CustomerManageContent.component.vue'),
    LocationManageContentComponent: () => import(
      /* webpackChunkName: "LocationManageMasterDataComponent" */
      '@/components/location/LocationManageContent.component.vue'),
    SideCard: () => import(
      '@/components/shared/SideCard/SideCard.component.vue'),
    AreaTaskListComponent: () => import(
      '@/components/shared/AreasTaskList.component.vue'),
  },
  mixins: [validationMixin],
})
export default class JobManageComponent extends mixins(ErrorMessageHandlerMixin) {
  @CompanyStore.Getter('activeCompany')
  public _company!: Company;
  @UserStore.Getter('roles')
  public _roles!: UserRole[];
  @CustomerStore.Action('loadCustomerAction')
  public loadCustomerAction!: (payload: { customerId: string, store: boolean }) => Promise<Customer>;
  @CustomerStore.Action('loadLocationAction')
  public loadLocationAction!: (arg: { locationId: string, shouldBeStored: boolean }) => Promise<Location>;
  @cleanTimeStore.Action(cleanTimeStoreActions.CREATE_CLEAN_TIME_ACTION)
  public createCleanTimeAction!: (cleanTime: CleanTime) => Promise<CleanTime>;
  @cleanTimeStore.Action(cleanTimeStoreActions.UPDATE_CLEAN_TIME_ACTION)
  public updateCleanTimeAction!: (payload: { cleanTime: CleanTime, splitAtDate: string }) => Promise<CleanTime>;
  @cleanTimeStore.Action(cleanTimeStoreActions.DELETE_CLEAN_TIME_ACTION)
  public deleteCleanTimeAction!: (cleanTimeId: string) => Promise<CleanTime>;
  @cleanTimeStore.Action(cleanTimeStoreActions.LOAD_CLEAN_TIMES_ACTION)
  public loadCleanTimesAction!: (locationId: string) => Promise<CleanTime[]>;
  //endregion
  /**
   * Current CleanTime thats being edited
   */
  public cleanTime: CleanTime = new CleanTime();

  public managedUser: User | undefined = undefined;
  /**
   * Selected Customer
   */
  public selectedCustomer: Customer = new Customer();
  /**
   * Bool to disable the location selection
   */
  public customerSelected: boolean = false;
  /**
   * Bools to show the 'empty' text
   */
  public emptyCustomer: boolean = false;
  public emptyLocation: boolean = false;
  public emptyAreas: boolean = false;
  public missingName: boolean = false;
  /**
   * The customers to that can be chosen in the autocomplete
   */
  public customers: Customer[] = [];
  /**
   * Selected Location
   */
  public selectedLocation: Location = new Location();
  /**
   * The Locations of the selected Customer
   */
  public locations: Location[] = [];
  /**
   * The Areas of the selected location
   */
  public locationAreas: Area[] = [];
  /**
   * Bool to show the Customer Modals
   */
  public showManageCustomerModal: boolean = false;
  /**
   * Bool to show the Location Modal
   */
  public showManageLocationModal: boolean = false;

  /**
   * Possible Task Color
   */
  public colors = [
    this.$colorHandler.getThemeColor('task-default'),
    this.$colorHandler.getThemeColor('task-red'),
    this.$colorHandler.getThemeColor('task-yellow'),
    this.$colorHandler.getThemeColor('task-blue'),
    this.$colorHandler.getThemeColor('task-green')];

  get address(): Address {
    return this._location.address ?? this._customer?.address!;
  }

  get contactPerson(): ContactPerson {
    return this._location?.contactPerson ?? this._customer?.contactPerson!;
  }

  /**
   * Prop to auto insert given information
   */
  @Prop({default: () => false})
  public autoInsert!: boolean;
  @Prop({default: false})
  public show!: boolean;
  private managedCustomer: Customer | undefined = undefined;
  private managedLocation: string | undefined = undefined;
  private showManageUserModal: boolean = false;

  private splitAtDate: string = '';

  @UserStore.Getter('users')
  private _users!: User[];
  @CustomerStore.Getter('customers')
  private customersFromStore!: Customer[];
  @CustomerStore.Action('loadCustomersAction')
  private loadCustomersAction!: (companyId: string) => Promise<Customer[]>;
  @CompanyStore.Mutation('storeCompany')
  private storeCompany!: (company: Company) => any;
  /**
   * Current Selected Step
   * in the Stepper
   */
  private stepperStep: number = 1;
  // Save the furthest step to be able to jump between steps if nothing has changed
  private furthestStep: number = 1;
  private submitted: boolean = false;
  private vuelidate!: Validation;
  private onEdit: boolean = false;
  private loaded: boolean = false;
  private validateStep: any[] = [this.validateFirstStep, this.validateSecondStep, this.validateThirdStep];

  public get availableUsers(): User[] {
    return this._users.filter((user) => user.active);
  }

  public get allLocations(): Location[] {
    return this.locations.filter((locations) => locations.active);
  }

  //region Store Getter, Action & Mutations
  @CustomerStore.Getter('location')
  public _location!: Location;

  private get location() {
    return this._location;
  }

  @CustomerStore.Getter('customer')
  private _customer!: Customer;

  // This is needed for a watch
  private get customer() {
    return this._customer;
  }

  private get stepperHeader(): string[] {
    return [
      this.$t('CLEANTIME_OVERVIEW.NEW_CLEANTIME_HEADER.CUSTOMER_LOCATION').toString(),
      this.$t('CLEANTIME_OVERVIEW.NEW_CLEANTIME_HEADER.PLAN').toString(),
      this.$t('CLEANTIME_OVERVIEW.NEW_CLEANTIME_HEADER.DURATION').toString(),
      this.$t('CLEANTIME_OVERVIEW.NEW_CLEANTIME_HEADER.EMPLOYEE').toString(),
    ];
  }

  /**
   * Initial loading of all customers from Store
   */
  public async loadCustomers(): Promise<void> {
    if (this.customersFromStore.length === 0) {
      await this.loadCustomersAction(this.$route.params.companyId).then((value) => this.customers = value);
    } else {
      this.customersFromStore.forEach((value) => this.customers.push(value));
    }
    // Note: if this array is empty, no customer will be displayed in the autocomplete even if the v-model variable has a value
    // I don't know why it's empty if reloading the CustomerDashboard, that's why the loadCustomerAction
    this.customers = this.customers.filter((customer) => customer.active);
  }

  public initialise() {
    // Set the Customer and location

    this.loadCustomers();
    this.selectedCustomer = this._customer && this.autoInsert ? (
      this.customerSelected = true,
        this.locations = this._customer.locations,
        this._customer
    ) : new Customer();
    this.selectedLocation = this.autoInsert && this._location ? (
      this.stepperStep = this.furthestStep = 2,
        this._location
    ) : new Location();

    this.loaded = true;
  }

  public getUserFullName(user: User): string {
    return user.firstName + ' ' + user.lastName;
  }

  public getUserId(user: User): string {
    return user.id!;
  }

  public getCustomerName(customer: Customer): string {
    return customer.name!;
  }

  public getCustomerId(customer: Customer): string {
    return customer.id!;
  }

  public getLocationName(location: Location): string {
    return location.name!;
  }

  public getLocationId(location: Location): string {
    return location.id!;
  }

  public closeLocationManageForm(): void {
    this.showManageLocationModal = false;
    // reset the location
    this.managedLocation = undefined;
  }

  public onManageLocationFormDone(location: Location) {
    const index = this.locations.findIndex((value) => value.id === location.id);
    if (index === -1) {
      this.locations.push(location);
    }
    this.selectedLocation = location;
    if (index > -1) {
      this.locations.splice(index, 1, location);
    }
    this.onLocationChange();
    this.closeLocationManageForm();
  }

  /**
   * Resets the CleanTimeObject and its dialog textfields
   */
  public resetJobDialog(): void {
    // Resets the current CleanTime object
    this.cleanTime = new CleanTime();

    // sets Monday as default day for the new cleanTime component
    this.cleanTime.byWeekDay = [];
    this.cleanTime.byWeekDay.push('monday');

    // let it scroll to top again
    // document.getElementsByClassName('v-dialog--active')[0].scrollTop = 0;
    this.onEdit = false;

    // Reset stepper to first step
    this.backToStep(1, true);
  }

  /**
   * Sends Event to Close the Modal, resets all Input
   */
  public cancelButtonClicked(): void {
    this.showManageUserModal = false;
    this.resetJobDialog();
    this.$emit('exitModal', null);
  }

  public onEditLocation(location: Location): void {
    if (this.selectedLocation.id !== location.id || this.managedLocation === undefined) {
      this.managedLocation = location.id;
    }
    this.onLocationChange();
    this.showManageLocationModal = true;
  }

  public async onCustomerChange(): Promise<void> {
    this.showManageCustomerModal = false;
    this.selectedLocation = new Location();
    try {
      // The locations are not in the customers array, so load the specific customer
      await this.loadCustomerAction({customerId: this.selectedCustomer.id!, store: false}).then((value) => {
        this.selectedCustomer = value;
        this.locations = value.locations;
      });
    } catch (e: any) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }
    this.customerSelected = true;
    this.emptyCustomer = false;
    this.furthestStep = 1;
  }

  public async onLocationChange(): Promise<void> {
    this.showManageLocationModal = false;
    // location doesn't need to be loaded
    this.furthestStep = 1;
  }

  /**
   * Validates the user of the first stepper step
   */
  public validateFirstStep(): void {
    if (this.showManageLocationModal || this.showManageCustomerModal) {
      return this.$notifyInfoSimplified('GENERAL.NOTIFICATIONS.OPEN_MANAGE_COMPONENT');
    }
    if (!this.selectedCustomer.name) {
      this.emptyCustomer = true;
    }
    if (this.selectedLocation.name) {
      this.stepperStep = 2;
      if (this.furthestStep === 1) {
        this.furthestStep = 2;
      }
    } else {
      this.emptyLocation = true;
      this.furthestStep = 1;
    }
  }

  /**
   * Validates the user of the second stepper step
   */
  public validateSecondStep(): void {
    if (this.areasValid()) {
      this.stepperStep = 3;
      if (this.furthestStep === 2) {
        this.furthestStep = 3;
      }
    } else {
      this.furthestStep = 2;
    }
  }

  /**
   * Validates the user of the third stepper step
   */
  public validateThirdStep(): void {
    // touches the validation of the first inputs
    this.vuelidate.$touch();

    if (this.vuelidate.$invalid) {
      // if invalid scroll to first error input
      // OPTIMIZE the use of an css selector of a vuetify property is not always safe
      if (!(this.vuelidate as any).cleanTime!.byWeekDay!.$invalid) {
        this.furthestStep = 3;
        requestAnimationFrame(() => this.$vuetify.goTo('.error--text', {offset: 50}));
      }
    } else {
      // on Success the stepper is increased
      this.stepperStep = 4;
      this.furthestStep = 4;
    }
  }

  private areasValid() {
    if (this.cleanTime.areas.length > 0) {
      this.emptyAreas = false;
    } else {
      this.emptyAreas = true;
      return false;
    }
    this.missingName = false;
    for (const area of this.cleanTime.areas) {
      if (area.tasks.length > 1) {
        // counter to remove a maximum of tasks.length - 1
        let removedTasks = 0;
        area.tasks = area.tasks.filter((task, index, array) => {
          if (!!task.name) {
            return true;
          }
          removedTasks++;
          return array.length - removedTasks < 1;
        });
      }
      // just change one time
      if (!this.missingName) {
        this.missingName = !area.name || area.name === '' || !area.tasks[0].name || area.tasks[0].name === '';
      }
    }
    return !this.missingName;
  }

  /**
   * Gets the customercopy of the Customer-Manage-Component
   * @param customer
   */
  public async onCustomerExit(customer: Customer): Promise<void> {
    if (customer) {
      const index = this.customers.findIndex((value) => value.id === customer.id);
      if (index > -1) {
        this.customers.splice(index, 1, customer);
      }
      if (index === -1) {
        this.customers.push(customer);
      }
      // this.selectedCustomer = customer;           // does not work here... whats the different?
      await this.loadCustomerAction({
        customerId: customer.id!,
        store: false,
      }).then((value) => this.selectedCustomer = value);

      this.customerSelected = true;
    }
    this.showManageCustomerModal = false;
  }

  public deleteCustomer(customer: Customer) {
    const index = this.customers.findIndex((value) => value.id === customer.id);
    this.customers.splice(index, 1);
    this.customerSelected = false;
    this.showManageCustomerModal = false;
  }

  public deleteLocation() {
    this.selectedLocation = new Location();
    this.showManageLocationModal = false;
  }

  public onUserExit(user: User | null) {
    if ((user !== null && user.id)) {
      this.cleanTime.plannedUsers.push(user);
    }
    this.showManageUserModal = false;
  }

  /**
   * sets the StepperCount
   */
  public backToStep(step: number, reset: boolean = false): void {
    this.stepperStep = step;
    if (reset) {
      this.furthestStep = step;
    }
  }

  /**
   * Called when cleanTime should be edited, inserts the cleanTime to the form
   * @param cleanTime
   * @param editMode
   */
  public setEditCleanTime(cleanTime: CleanTime, editMode: boolean = true): void {
    // Sets the Edit mode to true
    this.onEdit = editMode;

    // Set passed CleanTime as current
    this.cleanTime = cleanTime.copy() as CleanTime;

    // Do this after dom update
    this.$nextTick(() => {
      // Set Formatted Times for Start and End Picker
      if (this.cleanTime.times && this.cleanTime.times.length > 0) {
        // If minutes are over 60, another hour is added to the hour
        if (this.cleanTime.times[0].minute >= 60) {
          this.cleanTime.times[0].hour++;
          this.cleanTime.times[0].minute -= 60;
        }
      }

      // Set global users, to the planned users
      // @ts-ignore
      this.cleanTime.plannedUsers = this.cleanTime.plannedUsers.map((user: Partial<User>) => ({id: user.id}));
    });
  }

  /**
   * Submit method (user presses submit button)
   */
  private async onSubmit(): Promise<void> {
    // give order to areas and tasks
    this.cleanTime.areas.forEach((area, i) => {
      area.order = i;
      area.tasks.forEach((task, j) => task.order = j);
    });

    // map the users, to reduce the request body
    this.cleanTime.plannedUsers = this.cleanTime.plannedUsers.map((user: Partial<User>) => ({id: user.id!}));

    if (this.showManageUserModal) {
      return this.$notifyInfoSimplified('GENERAL.NOTIFICATIONS.OPEN_MANAGE_COMPONENT');
    }
    // if already submitted return
    if (this.submitted) {
      return;
    }

    // Sets the Form to Submitted
    this.submitted = true;

    // Tries to execute the api request
    try {
      // checks if EndDate is empty, than deletes it
      if (this.cleanTime.dateEnd === '') {
        delete this.cleanTime.dateEnd;
      }

      // Set Location of cleanTime to our current selected in our store
      this.cleanTime.locationId = this.selectedLocation.id!;

      // Remove recurrence from payload
      delete this.cleanTime.recurrence;

      // Remove Interval from Payload if we're in daily mode
      if (this.cleanTime.freq === 'daily') {
        this.cleanTime.interval = 1;

        // If all days are selected, the weekdays are set to null
        if (this.cleanTime.byWeekDay !== null && this.cleanTime.byWeekDay.length >= 7) {
          this.cleanTime.byWeekDay = null;
        }

        // strip all numbers for each day if byWeekDay is not undefined
        if (this.cleanTime.byWeekDay) {
          for (let i = 0; i < this.cleanTime!.byWeekDay?.length; i++) {
            const day = this.cleanTime!.byWeekDay![i];
            const matchedDay = day!.match(/[a-z]+/)![0];
            if (matchedDay) {
              this.cleanTime!.byWeekDay![i] = matchedDay;
            }
          }
        }
      }

      // Casts the value of the interval-string to a number
      this.cleanTime.interval = parseInt(this.cleanTime.interval + '');

      // Remove Recurrence from times array
      for (const time of this.cleanTime.times) {
        delete time.occurrences;
      }

      // cleanTime should be edited
      if (this.onEdit) {
        await this.editCleanTime(this.splitAtDate);
      } else {
        // create new CleanTime
        await this.createCleanTime();
      }

      // Send Close Modal to parent component
      this.$emit('exitModal', null);

      // resets the stepper
      this.resetJobDialog();

      // enables the buttons behaviour again
      this.submitted = false;
    } catch (e: any) {
      // enables the buttons behaviour again
      this.submitted = false;
      // shows the general error messages for creating CleanTimes
      this.$notifyErrorSimplified('CLEAN_TIME_COMPONENT.NOTIFICATIONS.ERROR.CREATE_CLEAN_TIME');
    }
    this.submitted = false;
  }

  /**
   * edits a certain, existing cleanTime by the inputs the user set inside the form
   * the cleanTime data is updated by sending an event to the customer
   * dashboard which handles the update
   */
  private async editCleanTime(splitAtDate: string): Promise<void> {
    try {
      // Remove Recurrence from times array
      for (const time of this.cleanTime.times) {
        if (!time.occurrences) {
          delete time.occurrences;
        }
      }

      await this.updateCleanTimeAction({cleanTime: this.cleanTime, splitAtDate});

      await this.loadCleanTimesAction(this.location.id!);

      // Shows success notification
      this.$notifySuccessSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.EDIT_CLEAN_TIME.SUCCESS');

      // Update CleanTime by sending an Event to the Customer Dashboard
      this.$emit('updatedCleanTime', this.cleanTime);
    } catch (e: any) {
      // Shows error notification
      this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.EDIT_CLEAN_TIME.ERROR');
    }
  }

  /**
   * sends create request to the api,
   * creates the cleanTime
   */
  private async createCleanTime(): Promise<void> {
    // Remove Recurrence from times array
    for (const time of this.cleanTime.times) {
      delete time.occurrences;
    }

    // Sends the CleanTime object to the API to create an instance in the backend
    const cleanTime = await this.createCleanTimeAction(this.cleanTime);

    // Notify with a sucess message,
    this.$notifySuccessSimplified('CLEAN_TIME_COMPONENT.NOTIFICATIONS.CREATE_CLEAN_TIME');

    // send createdCleanTime event to parent component
    this.$emit('createdCleanTime', cleanTime);
  }

  private onInput(data: { cleanTime: CleanTime, vuelidate: any }): void {
    this.cleanTime = data.cleanTime;
    this.vuelidate = data.vuelidate;
  }

  private changedAreas() {
    this.furthestStep = 2;
  }

  private changeValidFrom(date: string) {
    this.splitAtDate = date;
  }

  @Watch('show')
  private watchJobManage() {
    if (this.show) {
      this.initialise();
    }
  }

  @Watch('location')
  private watchLocation(value: Location) {
    this.emptyLocation = false;
    this.furthestStep = 1;
    this.stepperStep = 1;
    this.selectedLocation = value;
  }

  @Watch('customer')
  private watchCustomer(value: Customer) {
    if (value) {
      this.locations = value.locations;
      this.managedCustomer = this.selectedCustomer;
    }
  }

  private manageCustomer(customerToManage: Customer) {
    this.showManageCustomerModal = true;
    if (this.selectedCustomer.id !== customerToManage.id || this.managedCustomer === undefined) {
      this.managedCustomer = customerToManage;
    }
  }

  private createCustomer() {
    this.closeMenu('customer');
    this.showManageCustomerModal = !this.showManageCustomerModal;
    this.managedCustomer = undefined;
    this.selectedCustomer = new Customer();
    this.selectedLocation = new Location();
    this.customerSelected = false;
  }

  private createLocation() {
    this.closeMenu('location');
    this.managedLocation = undefined;
    this.showManageLocationModal = !this.showManageLocationModal;
    this.selectedLocation = new Location();
  }

  private createUser() {

    this.closeMenu('plannedUsers');
    this.managedUser = undefined;
    this.showManageUserModal = !this.showManageUserModal;

  }

  public closeMenu(refName: 'customer' | 'location' | 'plannedUsers') {
    (this.$refs[refName] as any).$refs.myAutocomplete.$refs.menu.save();
  }

  private changedInput(step: number) {
    if (this.furthestStep > step) {
      this.furthestStep = step;
    }
  }
}
